<div class="page-container">
  <div class="header">
    <div class="container header__container">
      <div class="header-logo__container">
        <img src="../assets/logo.png" class="header-logo" />
      </div>
      <div class="icon-container d-none d-sm-flex">
        <a
          href="https://www.facebook.com/www.lasercutting.lk"
          target="”_blank”"
        >
          <img src="../assets/facebook.svg" class="icon" />
        </a>

        <a
          href="https://youtube.com/channel/UCybb-XWbf6QbELhdCKltMLw"
          target="”_blank”"
        >
          <img src="../assets/youtube.svg" class="icon" />
        </a>

        <a href="https://vm.tiktok.com/ZSED18Qp/" target="”_blank”">
          <img src="../assets/tiktok.svg" class="icon" />
        </a>

        <a href="mailto:Lasercuttinglkinfo@gmail.com">
          <img src="../assets/mail.svg" class="icon" />
        </a>

        <a href="tel:+94777796986">
          <img src="../assets/phone.svg" class="icon" />
        </a>
      </div>
      <div class="d-flex d-sm-none">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="row w-100 h-100 m-0 p-0">
    <div class="col-12 col-md-6 h-100 m-0">
      <div
        class="text-container container align-items-center align-items-md-start"
      >
        <span class="txt padding-res">LASER CUTTING</span>
        <span class="txt padding-res">& ENGRAVING</span>
        <span class="txt padding-res txt__sub">SERVICE PROVIDER</span>
        <img class="padding-res mt-2" src="assets/banner.png" width="80%" />
      </div>
    </div>
    <div class="col-12 col-md-6 d-none d-md-flex h-100 m-0 p-0">
      <div class="image-container">
        <img src="../assets/machine.png" width="100%" />
      </div>
    </div>
  </div>
</div>

<div class="banner row slide-in-left">
  <span class="banner__text"> This site is under construction </span>
  <div class="banner__img">
    <img src="../assets/setup.gif" width="100%"/>
  </div>
</div>

<mat-menu #menu="matMenu">
  <a
    class="menu-button"
    mat-button
    href="https://www.facebook.com/www.lasercutting.lk"
    target="”_blank”"
  >
    <img src="../assets/facebook.svg" class="icon" />
    Facebook Page
  </a>

  <a
    class="menu-button"
    mat-button
    href="https://youtube.com/channel/UCybb-XWbf6QbELhdCKltMLw"
    target="”_blank”"
  >
    <img src="../assets/youtube.svg" class="icon" />
    Youtube Channel
  </a>

  <a
    mat-button
    href="https://vm.tiktok.com/ZSED18Qp/"
    target="”_blank”"
    class="menu-button"
  >
    <img src="../assets/tiktok.svg" class="icon" />
    TikTok page
  </a>

  <a mat-button href="mailto:Lasercuttinglkinfo@gmail.com" class="menu-button">
    <img src="../assets/mail.svg" class="icon" />
    Email us
  </a>

  <a mat-button href="tel:+94777796986" class="menu-button">
    <img src="../assets/phone.svg" class="icon" />
    Call us
  </a>
</mat-menu>
